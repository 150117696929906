<template>
  <div id="app">
    <el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="40%" :show-close="isfirst"
      :close-on-click-modal="isOff" center>
      <div style="height:240px">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="旧密码" prop="password">
            <el-input v-model="ruleForm.password" autocomplete="off" @change="encrypt"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pass">
            <el-input show-password type="password" v-model="ruleForm.pass" autocomplete="off" clearable></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input show-password type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="margin:20px 0;">
            <el-button style="margin:10px;width:20%" plain type="primary" @click="reset(ruleForm)">重置</el-button>
            <el-button class="btn" type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button class="btn" @click="goBack()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { encrypt } from "../../utils/jsencrypt";
import User from '@/api/manageApi/User';
import PasswordHistory from "@/api/manageApi/PasswordHistory";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      var reg1 =
        /^(?=.*\d)(?=.*[~!@#$%^&*+-?.])(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*.]{8,}$/; //密码必须是8位以上、必须含有字母、数字、特殊符号
      var reg2 = /(123|234|345|456|567|678|789|012)/; //不能有3个连续数字
      if (!reg1.test(value)) {
        callback(new Error("密码必须是8位以上、必须含有字母、数字、特殊符号"));
      } else if (reg2.test(value)) {
        callback(new Error("不能有3个连续数字"));
      } else {
        callback();
      }
    };
    return {
      userInfoVo: {
        id: "",
        username: "",
        password: "",

        passwordNew: "",
        passwordOld: "",
      },
      passwordHistoryVo: {
        id: "",
        username: "",
        passwordNew: "",
        passwordOld: "",
      },
      isfirst: false,
      ruleForm: {
        id: "",
        username: "",
        password: "", // 旧密码
        pass: "", // 新密码
        checkPass: "", // 确认新密码
      },
      rules: {
        pass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },

      centerDialogVisible: true,
      isOff: false,
    };
  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    reset(data) {
      data.pass = ''
      data.password = ''
    },
    // 判断旧密码是否正确 
    encrypt() {
      let aa = encrypt(this.ruleForm.password) // 加密
      this.userInfoVo.password = aa
      // User.isPassword(this.userInfoVo).then((res) => {

      // })
    },
    isLogin() {
      User.getOneUserInfo().then((resp) => {
        if (resp.data.message === "需要修改密码") {
          this.isOff = false
        } else {
          this.isOff = true
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.clearpasswordHistoryVo()
          //赋值密码
          this.passwordHistoryVo.passwordOld = encrypt(this.ruleForm.password)
          this.passwordHistoryVo.passwordNew = encrypt(this.ruleForm.pass)
          this.passwordHistoryVo.username = this.ruleForm.username

          PasswordHistory.updatePassword(this.passwordHistoryVo).then((res) => {

            if (res.data.message === "不能修改近8次相同的密码") {
              this.$router.push("/ChangePasd");
              this.$alert('不能修改近8次相同的密码', '提示', {
                confirmButtonText: '确定',
              })
              // this.$alert('<strong>不能修改近8次相同的密码</strong>', '提示', {
              //   dangerouslyUseHTMLString: true
              // });
            } else {
              this.centerDialogVisible = false;
              this.$router.push("/");
              this.$alert('修改密码成功', '提示', {
                confirmButtonText: '确定',
              })
            }

          }
          )
        } else {
          return false;
        }
      });
    },
    clearpasswordHistoryVo() {
      this.passwordHistoryVo.passwordOld = "";
      this.passwordHistoryVo.passwordNew = "";
      this.passwordHistoryVo.username = "";
    }
  },
};
</script>

<style scope lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/dptBG.jpg");

  .btn {
    width: 20%;
    float: right;
    margin: 10px;
  }
}
</style>